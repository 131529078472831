<template>
<div>
      <h3>{{ consent.configs.custom.info }}</h3>
         <b-form v-on:submit.prevent class="consensu-cookie-form">
            <div class="consensu-cookie-list accordion" role="tablist" v-if="version.length > 0">
               <template v-for="(item, idx) in version" >
                  <b-card no-body class="mb-1" :key="idx">
                     <b-card-header header-tag="header" class="p-1 text-left" role="tab"   variant="info">
                        <span class="category-name" v-b-toggle="'accordion-' + idx">{{ item.category.name }} ({{ item.cookies.length }})</span>
                        <div class="form-check form-switch switch">
                           <input class="form-check-input" type="checkbox" 
                            :id="`consensu-category-${item.category._id}`"
                            :value="item.category._id"
                            :disabled="item.category.disabled"
                            :checked="selected[item.category._id].cookies.length === item.cookies.length"
                             @click="toggleCategory(item.category._id, $event.target.checked)"
                           >
                           <label class="form-check-label" :for="`consensu-category-${item.category._id}`"></label>
                        </div>
                     </b-card-header>
                     <b-collapse :id="`accordion-${idx}`" accordion="accordion" role="tabpanel">
                        <b-card-body>
                           <b-card-text class="category-description">{{item.category.description}}</b-card-text>
                           <table class="table table-striped">
                              <thead>
                                 <tr>
                                    <th scope="col">Cookie</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col"></th>
                                 </tr>
                              </thead>
                              <tbody >
                                 <tr v-for="cookie in item.cookies" :key="cookie._id">
                                    <td >
                                       <div class="cookie-name">
                                          <span>{{cookie.name}}</span>
                                          <small>{{cookie.expiration}}</small>
                                       </div>
                                    </td>
                                    <td>{{cookie.description}}</td>
                                    <td>
                                       <div class="form-check form-switch switch">
                                          <input class="form-check-input" type="checkbox"
                                            v-if="selected[item.category._id]"
                                            :value="cookie._id"
                                            v-model="selected[item.category._id].cookies"
                                            :data-cookie-name="cookie.name"
                                            :id="cookie._id"
                                            :disabled="item.category.disabled"
                                            :class="`consensu-category-${item.category._id}-cookie`"
                                          >
                                          <label class="form-check-label" :for="cookie._id"></label>
                                       </div>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                           
                        </b-card-body>
                     </b-collapse>
                  </b-card>
               </template>
               <p class="info-version">
               <span class="version">
                 <b>Identificação Única:</b> {{fingerprint}}
               </span>
               <span class="version">
                 <b>Versão:</b> {{versionID}}
               </span>
               <span class="lastUpdate">
                 <b>Última Atualização:</b> {{ new Date(version[0].lastUpdate).toLocaleString()}}
               </span>
               </p>
            </div>
         </b-form>
         </div>
</template>

<script>
  import mixEvents from "../../../mixins/events";
  export default {
    name: "box",
    mixins: [mixEvents],
    props: ["consent", "version"],
    components: {
      //   buttonCaller
    },
    data() {
      return {
        tabs: [
          {
            title: "Preferências de cookies",
            comp: "Cookies",
            menu: true,
          },
          {
            title: "Seus Direitos e Solicitações",
            comp: "Direitos",
            menu: true,
          },
          {
            title: "Política de Privacidade",
            comp: "Politica",
          },
          {
            title: "Contatos",
            comp: "Contatos",
          },
        ],
        tabIndex: 0,
        overlayClass: "",
        viewport: {
          desktop: {
            width: 800,
            height: 700,
          },
          mobile: {
            width: 400,
            height: 410,
          },
        },
      };
    },
    mounted() {
  
    },
    methods: {
      setTab(idx) {
        this.tabIndex = idx;
      },
      setConfig() {
        const width  = window.screen.availWidth || document.documentElement.clientWidth ||
        document.body.clientWidth;
        const height = window.screen.availHeight|| document.documentElement.clientHeight||
        document.body.clientHeight;

        console.log(width, height);
        // this.sendEvent("configs", {width, height})
        this.sendEvent("makeViewport", this.viewport);
      },
      custom() {
        this.overlayClass = "open-sign-in";
      },
      back() {
        this.overlayClass = "open-sign-up";
      },
    },
    computed: {},
  };
</script>

<style lang="scss">
  @import "../style.scss";
</style>